import React from 'react';
import phoneImage from '../assets/iphone_image.png'; // Ensure this is the correct image path

function CardAddition() {
  return (
    <section className="card-addition">
      <div className="card-addition-content">
        {/* New Blue Card */}
        <div className="blue-card">
          <h3 className='blue-card-test'>
            Payments and Awards Start here. <span style={{ color: '#FFD700' }}>Pay anywhere.</span>
          </h3>
        </div>

        {/* Existing White Card */}
        <div className="white-square">
          <div className="card-addition-text">
            <h2 style={{ color: '#0071BC' }}>
              Add all your credit cards to Churn℠ wallet.
            </h2>
            <h2 style={{ color: '#07be61' }}>We handle the rest.</h2>
          </div>
          <div className="phone-image">
            <img src={phoneImage} alt="iPhone Wallet" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CardAddition;