import React from 'react';
import test1 from '../assets/test1.png'; // Importing the images
import test2 from '../assets/test2.png';
import test3 from '../assets/test3.png';
import setupChurnButton from '../assets/setup_churn.png'; // Import the button image

function Features() {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <section className="features">
      <div style={{ alignContent: 'center', flexDirection: 'column', marginBottom: '50px' }} className="features-content">
        <h2 style={{ paddingLeft: '50px', paddingRight: '50px', color: '#07be61', fontSize: '30px', fontWeight: 'bold' }}>
          A Mobile Wallet That Maximizes Credit Card Rewards Automatically
        </h2>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <div 
            className="setup-button-wrapper"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img 
              src={setupChurnButton} 
              alt="Setup Churn" 
              className={`setup-button ${isHovered ? 'blurred' : ''}`}
              style={{ height: '75px', width: 'auto' }} // Make the button larger
            />
            {isHovered && <div style={{ color: 'white', fontWeight: 'bold' }} className="coming-soon-overlay">Coming Soon</div>}
          </div>
        </div>
        <p style={{ color: 'black', marginLeft: '50px', marginRight: '50px', marginTop: '100px', marginBottom: '50px', fontSize: '18px' }}>
          Churn℠ makes it easy to max out cash back and rewards by automatically selecting the card that will give you the biggest reward, right as you pay.
        </p>
        <div style={{ display: 'flex', marginLeft: '50px', marginRight: '50px', justifyContent: 'center' }} className="feature-list">
          <div className="feature-item">
            <div className="card">
              <div className="card-image" style={{ backgroundColor: '#00BF63' }}>
                <img src={test1} alt="Step 1" />
              </div>
              <div className="card-addition-text">
                <h3 style={{ fontWeight: 'bold' }}>Fast and easy</h3>
              </div>
            </div>
          </div>
          <div className="feature-item">
            <div className="card">
              <div className="card-image" style={{ backgroundColor: '#00BF63' }}>
                <img src={test2} alt="Step 2" />
              </div>
              <div className="card-addition-text">
                <h3 style={{ fontWeight: 'bold' }}>All Cards Accepted</h3>
              </div>
            </div>
          </div>
          <div className="feature-item">
            <div className="card">
              <div className="card-image" style={{ backgroundColor: '#00BF63' }}>
                <img src={test3} alt="Step 3" />
              </div>
              <div className="card-addition-text">
                <h3 style={{ fontWeight: 'bold' }}>Max rewards</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;