import React, { useEffect } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import CardAddition from './components/CardAddition';
import Footer from './components/Footer';
import { initScrollEffect } from './utils/scrollEffect';
import './App.css';

function App() {
  useEffect(() => {
    initScrollEffect();
  }, []);

  return (
    <div className="App">
      <Header />
      <Hero />
      <Features />
      <CardAddition />
      <Footer />
    </div>
  );
}

export default App;
