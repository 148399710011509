import React, { useState } from 'react';
import logo from '../assets/ChurnWalletLogoWhite.png';
import appStoreButton from '../assets/app-store-button.png';

function Header() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <header className="header">
      <div className="content-wrapper">
        <img src={logo} alt="ChurnWallet Logo" className="logo" />
        <div className="header-right">
          <div 
            className="app-store-button-wrapper"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img 
              src={appStoreButton} 
              alt="Download on the App Store" 
              className={`app-store-button ${isHovered ? 'blurred' : ''}`}
            />
            {isHovered && <div style={{ color: 'white', fontWeight: 'bold' }} className="coming-soon-overlay">Coming Soon</div>}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;