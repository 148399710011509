export function initScrollEffect() {
  const features = document.querySelector('.features');
  const cardAddition = document.querySelector('.card-addition');
  const cards = document.querySelectorAll('.feature-item .card'); // Select all cards

  if (!features || !cardAddition) return;

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const featuresBottom = features.offsetTop + features.offsetHeight;
    const cardAdditionTop = cardAddition.offsetTop;

    const transitionStart = featuresBottom - window.innerHeight / 2;
    const transitionEnd = cardAdditionTop - window.innerHeight / 2;

    // Print statements for debugging
    console.log('Scroll Position:', scrollPosition);
    console.log('Features Bottom:', featuresBottom);
    console.log('Card Addition Top:', cardAdditionTop);
    console.log('Transition Start:', transitionStart);
    console.log('Transition End:', transitionEnd);

    if (scrollPosition > transitionStart && scrollPosition < transitionEnd) {
      const progress = (scrollPosition - transitionStart) / (transitionEnd - transitionStart);
      
      // Interpolate between white and blue for background color
      const r = Math.round(255 - (255 * progress)); // Red component decreases from 255 to 0
      const g = Math.round(255 - (255 * progress)); // Green component decreases from 255 to 0
      const b = Math.round(0 + (227 * progress)); // Blue component increases from 0 to 227

      // Set the background color of features and card addition
      features.style.backgroundColor = `rgb(${r}, ${g}, ${b})`;
      cardAddition.style.backgroundColor = `rgba(0, 113, 227, ${progress})`; // Smooth transition to blue

      // Adjust opacity of cards
      const cardOpacity = 1 - progress; // Calculate opacity based on scroll progress
      cards.forEach(card => {
        card.style.opacity = cardOpacity; // Set the opacity of each card
      });
    } else if (scrollPosition <= transitionStart) {
      features.style.backgroundColor = 'white'; // Keep solid white
      cardAddition.style.backgroundColor = 'white'; // Reset to white
      console.log('Background Set to White');
      cards.forEach(card => {
        card.style.opacity = 1; // Reset opacity to 1
      });
    } else if (scrollPosition >= transitionEnd) {
      features.style.backgroundColor = '#0071e3'; // Set to blue
      cardAddition.style.backgroundColor = '#0071e3'; // Set to blue
      console.log('Background Set to Blue');
      cards.forEach(card => {
        card.style.opacity = 0; // Set opacity to 0
      });
    }
  };

  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', handleScroll);

  // Initial call to set the correct color and opacity on page load
  handleScroll();
}