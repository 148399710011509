import React from 'react';
import heroVideo from '../assets/SM.mp4';

function Hero() {
  return (
    <section className="hero">
      <div className="hero-content">
        <video autoPlay loop muted playsInline className="hero-video">
          <source src={heroVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  );
}

export default Hero;